// src/config/openai-config.js
const config = {
  OPENAI_API_KEY: process.env.REACT_APP_OPENAI_API_KEY,
  MODEL: "gpt-4o",
  ENDPOINT: "https://api.openai.com/v1/chat/completions",
  
  getHeaders() {
    return {
      'Authorization': `Bearer ${this.OPENAI_API_KEY}`,
      'Content-Type': 'application/json'
    };
  }
};

export default config;