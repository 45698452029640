// src/pages/Onboarding.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Onboarding.css';

const Onboarding = () => {
  const navigate = useNavigate();

  return (
    <div className="onboarding-container">
      <div className="logo-container">
      <img
          src="/images/doctor.png" 
          alt="CancerBouncer Logo"
          className="logo-image"
        />
      </div>
      <h1>CancerBouncer</h1>
      <p className="tagline">Get Ahead of Cancer<br/>Discover Your Risk in Minutes</p>
      <div className="onboarding-buttons">
        <button onClick={() => navigate('/signup')} className="onboarding-btn">
          Sign Up
        </button>
        <button onClick={() => navigate('/login')} className="onboarding-btn">
          Login
        </button>
        <span
          onClick={() => navigate('/welcome')}
          className="guest-text"
        >
          Continue as Guest
        </span>
      </div>
      <p className="bottom-message">
        In memory of <a 
          href="https://www.instagram.com/jamesghambin/" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="memorial-link"
        >
          James Ghambin
        </a>
      </p>
    </div>
  );
};

export default Onboarding;
