// src/App.js
import React, { useState, useEffect, } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import FactScreen from './pages/FactScreen';
import Onboarding from './pages/Onboarding';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Recommendations from './pages/Recommendations';
import Assessment from './pages/Assessment';
import Profile from './pages/Profile';
import WelcomeScreen from './pages/WelcomeScreen';
import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout';
import { auth } from './firebase';

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [assessmentResults, setAssessmentResults] = useState(null);
  const location = useLocation();

  // Check if user is on a protected route that should show navbar
  const protectedRoutes = ['/dashboard', '/recommendations', '/profile'];
  const shouldShowNavbar = Boolean(
    isLoggedIn && 
    assessmentCompleted && 
    protectedRoutes.includes(location.pathname)
  );

 
  // Add debug logs
  console.log('App - Current State:', {
    isLoggedIn,
    assessmentCompleted,
    currentPath: location.pathname,
    shouldShowNavbar
  });
 
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('Auth State Changed:', { user: !!user });
      setIsLoggedIn(!!user);
    });

    return () => unsubscribe();
  }, []);

  // Debug log for state changes
  useEffect(() => {
    console.log('App State Updated:', {
      isLoggedIn,
      assessmentCompleted,
      currentPath: location.pathname,
      shouldShowNavbar
    });
  }, [isLoggedIn, assessmentCompleted, location.pathname, shouldShowNavbar]);

  return (
    <div className="app-container">
      <Layout showNavbar={shouldShowNavbar}>
        <Routes>
          <Route path="/" element={<FactScreen />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route 
            path="/welcome" 
            element={
              <WelcomeScreen 
                setIsLoggedIn={setIsLoggedIn}
              />
            } 
          />
          <Route 
            path="/login" 
            element={<Login setIsLoggedIn={setIsLoggedIn} />} 
          />
          <Route 
            path="/signup" 
            element={<Signup setIsLoggedIn={setIsLoggedIn} />} 
          />
          <Route
            path="/assessment"
            element={
              <Assessment
                setAssessmentCompleted={setAssessmentCompleted}
                setAssessmentResults={setAssessmentResults}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                assessmentCompleted={assessmentCompleted}
                assessmentResults={assessmentResults}
              />
            }
          />
          <Route
            path="/recommendations"
            element={
              <Recommendations
                assessmentCompleted={assessmentCompleted}
                assessmentResults={assessmentResults}
              />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                setIsLoggedIn={setIsLoggedIn}
                setAssessmentCompleted={setAssessmentCompleted}
                setAssessmentResults={setAssessmentResults}
              />
            }
          />
        </Routes>
      </Layout>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </Router>
  );
}