// src/pages/Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ArrowLeft } from 'lucide-react';
import './Login.css';

const Signup = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);  // Set login state
      navigate('/welcome');  // Changed this from '/dashboard' to '/welcome'
    } catch (error) {
      console.error('Signup error:', error);
      setError('Error signing up. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <button 
        onClick={() => navigate('/onboarding')} 
        className="back-button"
        aria-label="Go back to onboarding"
      >
        <ArrowLeft size={24} />
      </button>

      <form onSubmit={handleSignup} className="login-form">
        <h2>Sign Up</h2>
        {error && <div className="login-error">{error}</div>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
          required
        />
        <button type="submit" className="login-button">
          Sign Up
        </button>
        <div className="login-footer">
          <span onClick={() => navigate('/login')}>
            Already have an account? Login
          </span>
        </div>
      </form>
    </div>
  );
};

export default Signup;