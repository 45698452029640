// src/pages/WelcomeScreen.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './WelcomeScreen.css';

const WelcomeScreen = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();

  const handleStartAsGuest = () => {
    setIsLoggedIn(true);  // Treat guest users as logged in
    navigate('/assessment');
  };

  return (
    <div className="welcome-screen">
      <div className="header">
        <div className="logo-container">
          <span className="logo-heart">❤️</span>
          <span>CancerBouncer</span>
        </div>
      </div>

      <div className="content">
        <div className="doctor-circle">
          <img 
            src="/images/doctor3.jpg" 
            alt="Doctor illustration" 
            className="doctor-image"
          />
        </div>

        <h1>Welcome to CancerBouncer</h1>
        
        <p className="message">
          Your health is a priority. CancerBouncer helps you assess your cancer risk with a simple
          questionnaire. Let's take the first step towards a healthier future together.
        </p>

        <button 
          className="start-button"
          onClick={handleStartAsGuest}
        >
          Start Questionnaire
        </button>
      </div>
    </div>
  );
};

export default WelcomeScreen;