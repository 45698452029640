// src/services/openaiService.js
import axios from 'axios';
import config from '../config/openai-config';

const getRecommendations = async (riskScores) => {
  try {
    const response = await axios.post(
      config.ENDPOINT,
      {
        model: config.MODEL,
        messages: [
          {
            role: "system",
            content: "You are a health education assistant. Provide evidence-based health recommendations formatted in JSON. Always include numerical values where applicable and clear action items. Do not provide medical advice."
          },
          {
            role: "user",
            content: `Based on these cancer risk assessments:
              - General Cancer Risk: ${riskScores.general.risk}%
              - Lung Cancer Risk: ${riskScores.lung.risk}%
              - Colorectal Cancer Risk: ${riskScores.colorectal.risk}%
              - Breast Cancer Risk: ${riskScores.breast.risk}%
              - Prostate Cancer Risk: ${riskScores.prostate.risk}%

              Provide recommendations in this exact JSON structure:
              {
                "screenings": [{
                  "test": string,
                  "frequency": string,
                  "startAge": number,
                  "description": string
                }],
                "lifestyle": [{
                  "category": string,
                  "recommendation": string,
                  "impact": "High" | "Medium" | "Low"
                }]
              }`
          }
        ],
        temperature: 0.3
      },
      {
        headers: config.getHeaders()
      }
    );

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error getting OpenAI recommendations:', error);
    throw error;
  }
};

export { getRecommendations };