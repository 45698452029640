// src/pages/Recommendations.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Recommendations.css';

const Recommendations = ({ assessmentCompleted, assessmentResults }) => {
  const navigate = useNavigate();

  if (!assessmentCompleted || !assessmentResults) {
    return (
      <div className="recommendations-container">
        <p className="no-recommendations-message">
          You currently don't have any recommendations. Please complete your assessment to see recommendations.
        </p>
        <button
          onClick={() => navigate('/assessment')}
          className="dashboard-button"
        >
          Start Assessment
        </button>
      </div>
    );
  }

  const { recommendations } = assessmentResults;
  const { screenings = [], lifestyle = [] } = recommendations;

  return (
    <div className="recommendations-container">
      <h2>Your Personalized Recommendations</h2>

      <section className="recommendation-section">
        <h3>Recommended Screenings</h3>
        <div className="screening-list">
          {screenings.map((screening, index) => (
            <div key={index} className="screening-item">
              <h4>{screening.test}</h4>
              <p className="screening-frequency">Frequency: {screening.frequency}</p>
              <p className="screening-age">Start Age: {screening.startAge}</p>
              <p className="screening-description">{screening.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="recommendation-section">
        <h3>Lifestyle Recommendations</h3>
        <div className="lifestyle-list">
          {lifestyle.map((item, index) => (
            <div key={index} className="lifestyle-item">
              <h4>{item.category}</h4>
              <p className="lifestyle-recommendation">{item.recommendation}</p>
              <p className="lifestyle-impact">Impact: {item.impact}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Recommendations;