// src/pages/Profile.js
import React from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

const Profile = ({ setIsLoggedIn, setAssessmentCompleted, setAssessmentResults }) => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const isFirebaseUser = !!auth.currentUser;

  const handleRetakeAssessment = () => {
    navigate('/assessment');
  };

  const handleDeleteAccount = () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      user
        .delete()
        .then(() => {
          setIsLoggedIn(false);
          setAssessmentCompleted(false);
          setAssessmentResults(null);
          navigate('/onboarding');
        })
        .catch((error) => {
          console.error(error);
          alert('Error deleting account.');
        });
    }
  };

  const handleLogout = () => {
    if (isFirebaseUser) {
      // Handle Firebase user logout
      auth
        .signOut()
        .then(() => {
          setIsLoggedIn(false);
          setAssessmentCompleted(false);
          setAssessmentResults(null);
          navigate('/onboarding');
        })
        .catch((error) => {
          console.error(error);
          alert('Error signing out.');
        });
    } else {
      // Handle guest user logout
      setIsLoggedIn(false);
      setAssessmentCompleted(false);
      setAssessmentResults(null);
      navigate('/onboarding');
    }
  };

  return (
    <div className="profile-container">
      {/* Profile Picture and Information */}
      <div className="profile-header">
        <img
          src="/images/default-profile.png"
          alt="Profile"
          className="profile-picture"
        />
        <h2 className="profile-name">{isFirebaseUser ? auth.currentUser.email : 'Guest User'}</h2>
        {isFirebaseUser && <button className="edit-profile-button">Edit Profile</button>}
      </div>

      {/* Retake Assessment Section */}
      <div className="profile-section">
        <p>Assessment</p>
        <button className="retake-button" onClick={handleRetakeAssessment}>
          Retake Assessment
        </button>
      </div>

      {/* Delete Account Section - Only show for Firebase users */}
      {isFirebaseUser && (
        <div className="profile-section">
          <p>Delete Account</p>
          <button className="delete-button" onClick={handleDeleteAccount}>
            Delete Account
          </button>
        </div>
      )}

      {/* Log Out */}
      <p className="logout-text" onClick={handleLogout}>
        {isFirebaseUser ? 'Log Out' : 'Exit Guest Mode'}
      </p>
    </div>
  );
};

export default Profile;