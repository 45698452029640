// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import ErrorBoundary from '../components/ErrorBoundary';
import './Dashboard.css';

// First, define colors for each cancer type
const cancerColors = {
  'General Cancer Risk': '#ff3131',    // Bright Red
  'Lung Cancer': '#3346fa',      // Bright Blue
  'Colorectal Cancer': '#42c965', // Emerald
  'Breast Cancer': '#e01874',    // Purple
  'Prostate Cancer': '#eec935',  // Yellow
};

const LoadingSpinner = () => (
  <div className="loading-container">
    <div className="spinner"></div>
    <p>Loading your results...</p>
  </div>
);

const Dashboard = ({ assessmentCompleted, assessmentResults }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="dashboard-container">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  if (!assessmentCompleted || !assessmentResults) {
    return (
      <div className="dashboard-container">
        <p className="dashboard-message">
          You haven't completed an assessment yet. Take an assessment to see your results.
        </p>
        <button
          onClick={() => navigate('/assessment')}
          className="dashboard-button"
        >
          Start Assessment
        </button>
      </div>
    );
  }

  // Transform cancer risks for the chart
  const chartData = Object.values(assessmentResults.cancerRisks).map(cancer => ({
    name: cancer.name,
    risk: cancer.risk
  }));

  
  return (
    <ErrorBoundary>
      <div className="dashboard-container">
        <h2 className="dashboard-title">Your Cancer Risk Assessment Results</h2>

        <div className="risk-chart-container">
          <h3>Cancer Risk Factors</h3>
          <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              label={{ value: 'Risk Percentage (%)', angle: -90, position: 'insideLeft' }}
              domain={[0, 100]}
            />
            <Tooltip
              formatter={(value) => `${value}%`}
              labelStyle={{ color: '#666' }}
              contentStyle={{ backgroundColor: '#2c2c2c', border: 'none' }}
            />
            <Bar
              dataKey="risk"
              radius={[4, 4, 0, 0]}
              animationDuration={1000}
            >
              {
                chartData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={cancerColors[entry.name]}
                  />
                ))
                }
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="risk-summary">
          <h3>Risk Summary</h3>
          <div className="risk-grid">
            {chartData.map((cancer, index) => (
              <div key={index} className="risk-item">
                <div className="risk-card">
                  <span className="risk-name">{cancer.name}</span>
                  <span className="risk-percentage">{cancer.risk}% likelihood</span>
                  <div className="risk-bar">
                    <div
                      className="risk-bar-fill"
                      style={{ 
                        width: `${cancer.risk}%`,
                        backgroundColor: cancerColors[cancer.name]  // Add this line
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          onClick={() => navigate('/recommendations')}
          className="view-recommendations-button"
        >
          View Recommendations
        </button>
      </div>
    </ErrorBoundary>
  );
};

export default Dashboard;