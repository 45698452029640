// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import './Login.css';
import { ArrowLeft } from 'lucide-react';

const Login = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);  // Set login state
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password.');
    }
  };

  const handleForgotPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert('Password reset email sent.');
      } catch (error) {
        console.error('Password reset error:', error);
        alert('Error sending password reset email.');
      }
    } else {
      alert('Please enter your email to reset the password.');
    }
  };

  return (
    <div className="login-container">
      <button 
        onClick={() => navigate('/onboarding')} 
        className="back-button"
        aria-label="Go back to onboarding"
      >
        <ArrowLeft size={24} />
      </button>
      
      <form onSubmit={handleLogin} className="login-form">
        <h2>Login</h2>
        {error && <div className="login-error">{error}</div>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
          required
        />
        <button type="submit" className="login-button">
          Login
        </button>
        <button 
          type="button" 
          className="forgot-password-button" 
          onClick={handleForgotPassword}
        >
          Forgot Password?
        </button>
        <div className="login-footer">
          Don't have an account? <span className="signup-text" onClick={() => navigate('/signup')}>Sign Up</span>
        </div>
      </form>
    </div>
  )}

export default Login;