// src/components/Layout.js
import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children, showNavbar }) => {
  console.log('Layout - showNavbar:', showNavbar); // Debug log
  
  return (
    <div className="layout-container">
      <div className="main-content">
        {children}
      </div>
      {showNavbar && <Navbar />}
    </div>
  );
};

export default Layout;