// src/components/Navbar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { LayoutDashboard, ClipboardList, User } from 'lucide-react';

const Navbar = () => {
  return (
    <div className="navbar">
      <NavLink to="/dashboard" className="nav-item">
        <LayoutDashboard size={24} />
        <span>Dashboard</span>
      </NavLink>
      <NavLink to="/recommendations" className="nav-item">
        <ClipboardList size={24} />
        <span>Recommendations</span>
      </NavLink>
      <NavLink to="/profile" className="nav-item">
        <User size={24} />
        <span>Profile</span>
      </NavLink>
    </div>
  );
};

export default Navbar;
