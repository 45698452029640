// src/api.js
import { getRecommendations } from './services/openaiService';

// Risk calculation function
// src/api.js
const calculateRisks = (userData) => {
  // Validate input
  if (!userData || typeof userData !== 'object') {
    throw new Error('Invalid user data');
  }

  // Start with base risk
  let baseRisk = 5;
  
  // Age factor with validation
  const age = parseInt(userData.age);
  if (isNaN(age)) {
    throw new Error('Invalid age value');
  }
  if (age > 50) baseRisk += 15;
  else if (age > 40) baseRisk += 10;

  // BMI factor
  if (userData.bmi > 30) baseRisk += 10;
  else if (userData.bmi > 25) baseRisk += 5;

  // Family history
  if (userData.medicalHistory.familyCancer) baseRisk += 15;
  if (userData.medicalHistory.personalCancer) baseRisk += 20;

  // Lifestyle factors
  if (userData.lifestyle.smoking === 'current') baseRisk += 20;
  if (userData.lifestyle.alcohol === 'frequent') baseRisk += 10;
  if (userData.lifestyle.exercise === 'sedentary') baseRisk += 10;

  // Cap the risk at 95%
  baseRisk = Math.min(baseRisk, 95);

  // Calculate specific cancer risks
  const lungRisk = calculateLungRisk(userData, baseRisk);
  const colorectalRisk = calculateColorectalRisk(userData, baseRisk);
  const breastRisk = calculateBreastRisk(userData, baseRisk);
  const prostateRisk = calculateProstateRisk(userData, baseRisk);

  return {
    general: { name: "General Cancer Risk", risk: baseRisk },
    lung: { name: "Lung Cancer", risk: lungRisk },
    colorectal: { name: "Colorectal Cancer", risk: colorectalRisk },
    breast: { name: "Breast Cancer", risk: breastRisk },
    prostate: { name: "Prostate Cancer", risk: prostateRisk }
  };
};

// Helper functions for specific cancer risks
const calculateLungRisk = (userData, baseRisk) => {
  let risk = baseRisk;
  if (userData.lifestyle.smoking === 'current') risk += 30;
  else if (userData.lifestyle.smoking === 'former') risk += 15;
  if (userData.environmentalFactors.toxinExposure) risk += 20;
  return Math.min(risk, 95);
};

const calculateColorectalRisk = (userData, baseRisk) => {
  let risk = baseRisk;
  if (userData.age > 50) risk += 20;
  if (userData.medicalHistory.familyCancer) risk += 25;
  if (userData.lifestyle.diet === 'processed') risk += 15;
  return Math.min(risk, 95);
};

const calculateBreastRisk = (userData, baseRisk) => {
  let risk = userData.gender === 'Female' ? baseRisk : 0;
  if (userData.gender === 'Female') {
    if (userData.age > 50) risk += 20;
    if (userData.medicalHistory.familyCancer) risk += 30;
  }
  return Math.min(risk, 95);
};

const calculateProstateRisk = (userData, baseRisk) => {
  let risk = userData.gender === 'Male' ? baseRisk : 0;
  if (userData.gender === 'Male') {
    if (userData.age > 50) risk += 25;
    if (userData.medicalHistory.familyCancer) risk += 25;
  }
  return Math.min(risk, 95);
};

const sendAssessmentData = async (userData) => {
  try {
    // Validate user data
    if (!userData || typeof userData !== 'object') {
      throw new Error('Invalid user data');
    }

    // Validate required fields
    const requiredFields = ['age', 'gender', 'bmi'];  
    for (const field of requiredFields) {
      if (!(field in userData)) {
        throw new Error(`Missing required field: ${field}`);
      }
    }

    // Calculate risks
    console.log('Calculating risks for userData:', userData); // Debug log
    const riskScores = calculateRisks(userData);
    console.log('Calculated risk scores:', riskScores); // Debug log

    // Get recommendations from OpenAI
    console.log('Requesting OpenAI recommendations'); // Debug log
    const recommendations = await getRecommendations(riskScores);
    console.log('Received OpenAI recommendations:', recommendations); // Debug log

    // Parse the recommendations (they come as a string)
    const parsedRecommendations = JSON.parse(recommendations.replace(/```json\n|\n```/g, ''));

    // Return combined results
    return {
      cancerRisks: riskScores,
      recommendations: parsedRecommendations
    };
  } catch (error) {
    console.error('Error in sendAssessmentData:', error);
    if (error.message.includes('JSON')) {
      throw new Error('Error processing recommendations format');
    }
    throw error;
  }
};

export default sendAssessmentData;