// src/pages/Assessment.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Assessment.css';
import sendAssessmentData from '../api';
// import sendAssessmentData from '../mockApi.backup';  // Use mock API instead of real API
import assessmentService from '../services/assessmentService';

const Assessment = ({ setAssessmentCompleted, setAssessmentResults }) => {  
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('');
  
  const loadingSteps = [
    { progress: 0, text: 'Starting assessment...' },
    { progress: 20, text: 'Calculating General Cancer risk...' },
    { progress: 40, text: 'Calculating Lung Cancer risk...' },
    { progress: 60, text: 'Calculating Colorectal Cancer risk...' },
    { progress: 80, text: 'Calculating Breast Cancer risk...' },
    { progress: 90, text: 'Calculating Prostate Cancer risk...' },
    { progress: 100, text: 'Finalizing your results...' }
  ];

  const simulateProgress = async () => {
    for (const step of loadingSteps) {
      setProgress(step.progress);
      setProgressText(step.text);
      // Wait a bit between each step
      await new Promise(resolve => setTimeout(resolve, 800));
    }
  };

  const [formData, setFormData] = useState({
    // Basic Information
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    height: 0,
    weight: 0,

    // Lifestyle Factors
    smokingStatus: '',
    alcoholConsumption: '',
    physicalActivity: '',
    diet: '',

    // Medical History
    familyHistory: {
      cancer: false,
      heartDisease: false,
      diabetes: false,
      other: ''
    },
    personalHistory: {
      cancer: false,
      surgeries: '',
      chronicConditions: ''
    },

    // Symptoms
    currentSymptoms: {
      unexplainedWeightLoss: false,
      fatigue: false,
      fever: false,
      pain: false,
      digestiveIssues: false,
      skinChanges: false,
      other: ''
    },

    // Environmental Factors
    occupation: '',
    exposureToToxins: false,
    livingEnvironment: '',

    // Mental Health
    stressLevel: 0,
    sleepQuality: '',
    mentalHealthConditions: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [category, field] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [category]: {
          ...prev[category],
          [field]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };


  // Keep the original slider handler
  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: parseInt(value) }));
  };

  // Keep the original navigation handlers
  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  
  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    setProgress(0);
    setProgressText(loadingSteps[0].text);

    try {
      // Start progress animation
      simulateProgress();

      const heightInMeters = formData.height / 100;
      const bmi = (formData.weight / (heightInMeters * heightInMeters)).toFixed(1);
      
      const userData = {
        age: calculateAge(formData.dateOfBirth),
        gender: formData.gender,
        bmi: parseFloat(bmi),
        lifestyle: {
          smoking: formData.smokingStatus,
          alcohol: formData.alcoholConsumption,
          exercise: formData.physicalActivity,
          diet: formData.diet
        },
        medicalHistory: {
          familyCancer: formData.familyHistory.cancer,
          personalCancer: formData.personalHistory.cancer,
          chronicConditions: formData.personalHistory.chronicConditions
        },
        symptoms: formData.currentSymptoms,
        environmentalFactors: {
          occupation: formData.occupation,
          toxinExposure: formData.exposureToToxins
        },
        mentalHealth: {
          stressLevel: formData.stressLevel,
          sleepQuality: formData.sleepQuality
        }
      };

      const results = await sendAssessmentData(userData);
      
      console.log('Assessment Completed - Setting States:', {
        completed: true,
        results
      });

      setAssessmentCompleted(true);
      setAssessmentResults(results);  

      navigate('/dashboard');
    } catch (error) {
      console.error('Error submitting assessment:', error);
      setError('Failed to submit assessment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const calculateAge = (birthDate) => {
    if (!birthDate || isNaN(new Date(birthDate).getTime())) {
      throw new Error('Invalid birth date');
    }    
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  // Render different pages based on currentPage
  const renderPage = () => {
    switch (currentPage) {
      case 0:
        return renderBasicInfo();
      case 1:
        return renderLifestyleFactors();
      case 2:
        return renderMedicalHistory();
      case 3:
        return renderSymptoms();
      case 4:
        return renderEnvironmentalFactors();
      default:
        return null;
    }
  };

  const renderButtonGroup = (isLastPage = false) => (
    <div className="button-group">
      {currentPage > 0 && (
        <button onClick={handlePrevious} className="assessment-button">
          Back
        </button>
      )}
      {!isLastPage ? (
        <button onClick={handleNext} className="assessment-button">
          Next
        </button>
      ) : (
        <button 
          onClick={handleSubmit} 
          className="assessment-button submit-button"
          disabled={loading}
        >
          {loading ? 'Submitting...' : 'Submit Assessment'}
        </button>
      )}
    </div>
  );

  const renderBasicInfo = () => (
    <div className="question-page">
      <h2>Basic Information</h2>
      <p className="required-note">* Required fields</p>
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        value={formData.firstName}
        onChange={handleChange}
        className="assessment-input"
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        value={formData.lastName}
        onChange={handleChange}
        className="assessment-input"
      />
      <div className="assessment-question-group">
        <label className="required-field">Date of Birth</label>
        <input
          type="date"
          name="dateOfBirth"
          value={formData.dateOfBirth}
          onChange={handleChange}
          className="assessment-input"
          required
        />
      </div>
      <div className="assessment-question-group">
        <label className="required-field">Gender</label>
        <div className="gender-options">
          <label>
            <input
              type="radio"
              name="gender"
              value="Male"
              checked={formData.gender === 'Male'}
              onChange={handleChange}
              required
            />
            Male
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="Female"
              checked={formData.gender === 'Female'}
              onChange={handleChange}
            />
            Female
          </label>
          <label>
            <input
              type="radio"
              name="gender"
              value="Other"
              checked={formData.gender === 'Other'}
              onChange={handleChange}
            />
            Other/Prefer not to say
          </label>
        </div>
      </div>
      <div className="assessment-question-group">
        <label className="required-field">
          Height (cm):
          <input
            type="range"
            name="height"
            min="100"
            max="250"
            value={formData.height}
            onChange={handleSliderChange}
            className="slider"
            required
          />
          <span>{formData.height} cm</span>
        </label>
        <label className="required-field">
          Weight (kg):
          <input
            type="range"
            name="weight"
            min="30"
            max="200"
            value={formData.weight}
            onChange={handleSliderChange}
            className="slider"
            required
          />
          <span>{formData.weight} kg</span>
        </label>
      </div>
      {renderButtonGroup()}
    </div>
);


  const renderLifestyleFactors = () => (
    <div className="question-page">
      <h2>Lifestyle Factors</h2>
      <div className="assessment-question-group">
        <label>Smoking Status</label>
        <select
          name="smokingStatus"
          value={formData.smokingStatus}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select smoking status</option>
          <option value="never">Never smoked</option>
          <option value="former">Former smoker</option>
          <option value="current">Current smoker</option>
        </select>
      </div>
      <div className="assessment-question-group">
        <label>Alcohol Consumption</label>
        <select
          name="alcoholConsumption"
          value={formData.alcoholConsumption}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select alcohol consumption</option>
          <option value="none">None</option>
          <option value="occasional">Occasional (1-2 drinks/week)</option>
          <option value="moderate">Moderate (3-7 drinks/week)</option>
          <option value="frequent">Frequent (8+ drinks/week)</option>
        </select>
      </div>
      <div className="assessment-question-group">
        <label>Physical Activity Level</label>
        <select
          name="physicalActivity"
          value={formData.physicalActivity}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select activity level</option>
          <option value="sedentary">Sedentary (Little to no exercise)</option>
          <option value="light">Light (1-3 days/week)</option>
          <option value="moderate">Moderate (3-5 days/week)</option>
          <option value="active">Very Active (6-7 days/week)</option>
        </select>
      </div>
      <div className="assessment-question-group">
        <label>Diet Type</label>
        <select
          name="diet"
          value={formData.diet}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select diet type</option>
          <option value="balanced">Balanced diet</option>
          <option value="vegetarian">Vegetarian</option>
          <option value="vegan">Vegan</option>
          <option value="processed">Mostly processed foods</option>
        </select>
      </div>
      {renderButtonGroup()}
    </div>
  );

  const renderMedicalHistory = () => (
    <div className="question-page">
      <h2>Medical History</h2>
      <div className="assessment-question-group">
        <h3>Family History</h3>
        <label>
          <input
            type="checkbox"
            name="familyHistory.cancer"
            checked={formData.familyHistory.cancer}
            onChange={handleChange}
          />
          Family history of cancer
        </label>
        <label>
          <input
            type="checkbox"
            name="familyHistory.heartDisease"
            checked={formData.familyHistory.heartDisease}
            onChange={handleChange}
          />
          Family history of heart disease
        </label>
        <label>
          <input
            type="checkbox"
            name="familyHistory.diabetes"
            checked={formData.familyHistory.diabetes}
            onChange={handleChange}
          />
          Family history of diabetes
        </label>
        <textarea
          name="familyHistory.other"
          placeholder="Other relevant family medical history..."
          value={formData.familyHistory.other}
          onChange={handleChange}
          className="assessment-input"
        />
      </div>
  
      <div className="assessment-question-group">
        <h3>Personal Medical History</h3>
        <label>
          <input
            type="checkbox"
            name="personalHistory.cancer"
            checked={formData.personalHistory.cancer}
            onChange={handleChange}
          />
          Previous cancer diagnosis
        </label>
        <textarea
          name="personalHistory.surgeries"
          placeholder="Previous surgeries..."
          value={formData.personalHistory.surgeries}
          onChange={handleChange}
          className="assessment-input"
        />
        <textarea
          name="personalHistory.chronicConditions"
          placeholder="Chronic conditions..."
          value={formData.personalHistory.chronicConditions}
          onChange={handleChange}
          className="assessment-input"
        />
      </div>
      {renderButtonGroup()}
    </div>
  );
  
  const renderSymptoms = () => (
    <div className="question-page">
      <h2>Current Symptoms</h2>
      <div className="assessment-question-group">
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.unexplainedWeightLoss"
            checked={formData.currentSymptoms.unexplainedWeightLoss}
            onChange={handleChange}
          />
          Unexplained weight loss
        </label>
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.fatigue"
            checked={formData.currentSymptoms.fatigue}
            onChange={handleChange}
          />
          Persistent fatigue
        </label>
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.fever"
            checked={formData.currentSymptoms.fever}
            onChange={handleChange}
          />
          Recurring fever
        </label>
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.pain"
            checked={formData.currentSymptoms.pain}
            onChange={handleChange}
          />
          Unexplained pain
        </label>
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.digestiveIssues"
            checked={formData.currentSymptoms.digestiveIssues}
            onChange={handleChange}
          />
          Digestive issues
        </label>
        <label>
          <input
            type="checkbox"
            name="currentSymptoms.skinChanges"
            checked={formData.currentSymptoms.skinChanges}
            onChange={handleChange}
          />
          Skin changes
        </label>
        <textarea
          name="currentSymptoms.other"
          placeholder="Other symptoms..."
          value={formData.currentSymptoms.other}
          onChange={handleChange}
          className="assessment-input"
        />
      </div>
      {renderButtonGroup()}
    </div>
  );
  
  const renderEnvironmentalFactors = () => (
    <div className="question-page">
      <h2>Environmental Factors</h2>
      <div className="assessment-question-group">
        <input
          type="text"
          name="occupation"
          placeholder="Occupation"
          value={formData.occupation}
          onChange={handleChange}
          className="assessment-input"
        />
        
        <label>
          <input
            type="checkbox"
            name="exposureToToxins"
            checked={formData.exposureToToxins}
            onChange={handleChange}
          />
          Regular exposure to toxins or hazardous materials
        </label>
  
        <select
          name="livingEnvironment"
          value={formData.livingEnvironment}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select living environment</option>
          <option value="urban">Urban</option>
          <option value="suburban">Suburban</option>
          <option value="rural">Rural</option>
          <option value="industrial">Industrial Area</option>
        </select>
  
        <div className="stress-level">
          <label>Stress Level (0-10):</label>
          <input
            type="range"
            name="stressLevel"
            min="0"
            max="10"
            value={formData.stressLevel}
            onChange={handleSliderChange}
            className="slider"
          />
          <span>{formData.stressLevel}</span>
        </div>
  
        <select
          name="sleepQuality"
          value={formData.sleepQuality}
          onChange={handleChange}
          className="assessment-input"
        >
          <option value="">Select sleep quality</option>
          <option value="good">Good (7-9 hours)</option>
          <option value="fair">Fair (5-7 hours)</option>
          <option value="poor">Poor (&lt;5 hours)</option>
        </select>
      </div>
      {renderButtonGroup(true)}  {/* Pass true to indicate this is the last page */}
    </div>
  );

  const LoadingOverlay = () => (
    <div className="loading-overlay">
      <div className="progress-container">
        <div className="progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="progress-text">
          {progressText}
        </div>
      </div>
    </div>
  );

  return (
    <div className="assessment-container">
      {error && <div className="error-message">{error}</div>}
      {loading && <LoadingOverlay />}
      {renderPage()}
    </div>
  );
};

export default Assessment;