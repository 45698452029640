// src/pages/FactScreen.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FactScreen.css';
import styles from './FactScreen.css';
// Import images
import factScreen1Mobile from '../images/FactScreen1-mobile.jpg';
import factScreen1Desktop from '../images/FactScreen1-desktop.jpg';
import factScreen2Mobile from '../images/FactScreen2-mobile.jpg';
import factScreen2Desktop from '../images/FactScreen2-desktop.jpg';
import factScreen3Mobile from '../images/FactScreen3-mobile.jpg';
import factScreen3Desktop from '../images/FactScreen3-desktop.jpg';

const facts = [
  {
    text: '',
    backgrounds: {
      mobile: factScreen1Mobile,
      desktop: factScreen1Desktop
    }
  },
  {
    text: '',
    backgrounds: {
      mobile: factScreen2Mobile,
      desktop: factScreen2Desktop
    }
  },
  {
    text: '',
    backgrounds: {
      mobile: factScreen3Mobile,
      desktop: factScreen3Desktop
    }
  }
];

const FactScreen = () => {
  const navigate = useNavigate();
  const [currentFact, setCurrentFact] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

   // Handle window resize
   useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handlePrevious = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (currentFact > 0) {
      setCurrentFact(currentFact - 1);
    }
  };

  const handleNext = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (currentFact < facts.length - 1) {
      setCurrentFact(currentFact + 1);
    } else {
      navigate('/onboarding');
    }
  };

  const handleClick = () => {
    if (currentFact < facts.length - 1) {
      setCurrentFact(currentFact + 1);
    } else {
      navigate('/onboarding');
    }
  };

  const progress = ((currentFact + 1) / facts.length) * 100;


  return (
    <div
      className="fact-screen"
      style={{ 
        backgroundImage: `url(${facts[currentFact].backgrounds[isDesktop ? 'desktop' : 'mobile']})`
      }}
    >
      <div className="progress-container">
        <div className="progress-bar">
          <div 
            className="progress-fill" 
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
      <div className="fact-content">
        <div className="click-area left" onClick={handlePrevious} />
        <div className="click-area right" onClick={handleNext} />
        <p className="fact-text">{facts[currentFact].text}</p>
      </div>
    </div>
  );
};

export default FactScreen;
